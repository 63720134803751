<template>
  <div>
    <el-popover
      v-model="isPopover"
      placement="bottom"
      trigger="click"
    >
      <el-form
        ref="form"
        v-loading="listLoading"
        :model="filterOptions"
        label-position="top"
      >
        <el-form-item
          :label="$t('field.main_type')"
          prop="mainType"
        >
          <el-select
            v-model="filterOptions.mainType"
            :placeholder="$t('dataList.selectData')"
            filterable
            clearable
            style="width:100%;"
            multiple
          >
            <el-option
              v-for="option in mainType"
              :key="option"
              :label="$t(`general.${option}`)"
              :value="option"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('field.type')"
          prop="type"
        >
          <el-select
            v-model="filterOptions.type"
            :placeholder="$t('dataList.inputType')"
            filterable
            clearable
            multiple
            style="width:100%;"
            @clear="onRemoveType"
            @remove-tag="onRemoveType"
          >
            <el-option-group
              v-for="group in typeOptions.type"
              :key="group.label"
              :label="$t(`general.${group.label}`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item"
                :label="$t(`mainType.${item}`)"
                :value="item"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="!isSubTypeOptionsEmpty"
          :label="$t('field.subtype')"
          prop="subType"
        >
          <el-select
            v-model="filterOptions.subType"
            :placeholder="$t('dataList.inputSubtype')"
            filterable
            clearable
            multiple
            style="width:100%;"
          >
            <el-option-group
              v-for="group in subTypeOptions"
              :key="group.label"
              :label="$t(`mainType.${group.label}`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item"
                :label="$t(`subType.${item}`)"
                :value="item"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align: center;">
          <el-button
            class="buttonSecondaryReverse"
            @click="handleFilter"
          >
            {{ $t('general.Filter') }}
          </el-button>
        </el-form-item>
      </el-form>
      <el-button
        slot="reference"
        icon="el-icon-s-operation"
        type="text"
        class="headerTextColor"
      >
        {{ $t('general.Filter') }}
        <em class="el-icon-arrow-down el-icon--right" />
      </el-button>
    </el-popover>
    <template v-if="isFilterChanged">
      <el-divider direction="vertical" />
      <el-button
        size="small"
        icon="el-icon-close"
        class="buttonSecondaryReverse"
        @click="cleanFilter"
      >
        {{ $t('general.Clean') }}{{ $t('general.Filter') }}
      </el-button>
    </template>
  </div>
</template>

<script>
import { getDatatype } from '@/api/data'
export default {
  name: 'SearchFilter',
  props: {
    filterOptions: {
      type: Object,
      default: () => ({})
    },
    mainType: {
      type: Array,
      default: () => ({})
    },
    // selectedMainType: {
    //   type: Array,
    //   default: () => ({})
    // },
    // typeOptions: {
    //   type: Object,
    //   default: () => ({})
    // },
    params: {
      type: Object,
      default: () => ({})
    },
    totalPage: {
      type: Number,
      default: 0
    },
    deleteDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      listLoading: false,
      allOptions: [], // 主類型所有選項
      // selectedMainType: [], // 數據類型
      isPopover: false,
      isFilterChanged: false
    }
  },
  computed: {
    selectedMainType() {
      return this.filterOptions.mainType
    }, 
    typeOptions() {
      const typeOptions = {}
      const options = Object.entries(this.allOptions).filter((item) =>
        this.selectedMainType.includes(item[0]) ||
        this.selectedMainType.length === 0
      )
      typeOptions.type = options.map((item) => ({
        label: item[0],
        options: Object.keys(item[1]).map((item) => item)
      }))
      typeOptions.subType = options.flatMap((item) => {
        return Object.entries(item[1]).map((item) => ({
          label: item[0],
          options: item[1]
        }))
      })
      return typeOptions
    },
    subTypeOptions() {
      return this.typeOptions.subType.filter((item) =>
        this.filterOptions.type.includes(item.label)
      )
    },
    isSubTypeOptionsEmpty() {
      return this.subTypeOptions.flatMap((item) => item.options).length === 0
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.fetchDataType()
    },
    async fetchDataType() {
      this.allOptions = (await getDatatype()).data
    },
    onRemoveType() {
      this.filterOptions.subType = []
    },
    handleFilter() {
      this.listLoading = true
      this.isFilterChanged = true
      this.$emit('update')
      this.listLoading = false
      this.isPopover = false
    },
    cleanFilter() {
      this.listLoading = true
      this.isFilterChanged = false
      this.$refs['form'].resetFields()
      this.$emit('update')
      this.listLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select-group .el-select-dropdown__item {
  padding-left: 30px;
}
.el-select-group__wrap::after {
  display: none;
}
</style>
