<template>
  <div class="synthetic-container">
    <div class="synthetic-container-main">
      <div class="synthetic-bar">
        <span class="control-left">
          <span>{{ $t('navbar.SyntheticData') }}</span>
          <span class="control-text">
            <span>{{ $t('dataList.totalCount') }} : </span>
            <span>{{ formatToLocaleString(totalPage) }}</span>
          </span>
        </span>
        <span class="delete-button-container">
          <SearchFilter
            ref="searchFilter"
            :filter-options.sync="filterOptions"
            :main-type="mainType"
            :params="params"
            :total-page="totalPage"
            @update="handleFilter"
          />
        </span>
      </div>
      <div>
        <DataList
          :list-loading="listLoading"
          :table-data="tableData"
          :fields="fieldsData"
        />
      </div>
    </div>
    <!-- pagination -->
    <div class="pagination-container">
      <el-pagination
        :background="true"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageLimit"
        :total="totalPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { FormatNumber } from '@/Mixins'
import DataList from './components/DataList.vue'
import SearchFilter from './components/SearchFilter.vue'
import {
  getSdvData,
  getInfoData
} from '@/api/data'

const mainType = () => [
  'PhysicalFitness',
  'Sport',
  'Physiology'
]
const params = () => ({
  page: 1,
  main_type: mainType().toString(), // 數據類型 PhysicalFitness, Sports, Physiology
  page_limit: 20
})

export default {
  name: 'SyntheticData',
  components: {
    DataList,
    SearchFilter
  },
  mixins: [FormatNumber],
  data () {
    return {
      listLoading: false,
      params: params(),
      mainType: mainType(),
      filterOptions: {
        mainType: [],
        type: [],
        subType: []
      },
      tableData: [],
      fieldsData: [],

      currentPage: 1,
      pageLimit: 20,
      totalPage: 0
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.fetchInfo()
      this.fetchData()
    },
    async fetchInfo() {
      const res = await getInfoData(this.params)
      const resultFields = res.data.sdv_required_columns
      this.fieldsData = [
        // 'version',
        ...resultFields
      ]
      this.fieldsData = this.remove(this.fieldsData, 'created_at')
    },
    remove(arr, item) {
      const index = arr.indexOf(item)
      return [
        ...arr.slice(0, index),
        ...arr.slice(index + 1)
      ]
    },
    async fetchData() {
      this.listLoading = true
      const res = await getSdvData(this.params)
      this.tableData = res.data.result
      this.totalPage = res.data.page.total
      this.listLoading = false
    },
    handleFilter() {
      this.params = params()
      if (this.filterOptions.mainType.length > 0) this.params.main_type = this.filterOptions.mainType.toString()
      if (this.filterOptions.type.length > 0) this.params.type = this.filterOptions.type.toString()
      if (this.filterOptions.subType.length > 0) this.params.subType = this.filterOptions.subType.toString()
      this.fetchData()
    },
    // for pagination
    handleSizeChange(val) {
      this.params.page_limit = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.synthetic-container {
  .synthetic-container-main {
    position: relative;
    flex-direction: column;
    .el-table .el-button {
      border: none;
      background-color: transparent;
    }
  }
}

.synthetic-bar {
  display:flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .control-left{
    text-align: left;
    .control-text{
      margin-left: 10px;
      font-size: 16px;
    }
  }
  .delete-button-container {
    display: flex;
    text-align: end;
    .delete-button {
      margin-left: 10px;
    }
  }
}

/* pagination */
.pagination-container {
  @include justifyCenter;
  .el-pagination {
    margin-top: 10px;
    background-color: #f3f2f1;
  }
}
</style>
