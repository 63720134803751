<template>
  <div>
    <el-table
      v-loading="listLoading"
      stripe
      :data="tableData"
      @row-click="handleRowClick"
    >
      <el-table-column
        type="index"
        :label="$t('general.Index')"
        width="50"
      />
      <el-table-column
        v-for="(item, index) in fields"
        :key="index"
        :prop="item"
        :label="$t(`field.${item}`)"
      />
      <el-table-column :label="$t('field.created_at')">
        <template slot-scope="scope">
          <span>{{ UTCtoLocalTime(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <template slot="empty">
        <el-empty :description="$t('general.NoData')" />
      </template>
    </el-table>
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      class="data-detail"
      width="50%"
    >
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <el-button
            plain
            type="info"
            style="float: right; padding: 10px"
            @click="dialogVisible = false"
          >
            {{ $t('general.Close') }}
          </el-button>
        </div>
        <div
          v-for="data in selectedData"
          :key="data.index"
          class="text item"
        >
          <template v-if="data.name === 'created_at'">
            {{ $t(`field.${data.name}`) }} : {{ UTCtoLocalTime(data.value) }}
          </template>
          <template v-if="data.name !== '_id'&& data.name !== 'created_at'">
            {{ $t(`field.${data.name}`) }} : {{ data.value }}
          </template>
        </div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { UTCtoLocalTime } from '@/utils/handleTime'
export default {
  name: 'DataList',
  props: {
    listLoading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialogVisible: false,
      selectedData: []
    }
  },
  methods: {
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    handleRowClick(row) {
      this.dialogVisible = true
      this.selectedData = []
      const noShow = [
        'Unnamed: 0'
      ]
      const order = [
        'main_type',
        'type',
        'subtype',
        'name',
        'device_type',
        'place_type',
        'city',
        'gender',
        'birth_year',
        'version'
      ]
      let i = 1
      for (const key in row) {
        if (!noShow.find(el => el === key)) {
          i++
          this.selectedData.push({
            name: key,
            value: row[key],
            order: order.find(el => el === key) ? order.findIndex(el => el === key) : i * 100
          })
        }
      }
      this.selectedData.sort((a, b) => a.order - b.order)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.data-detail {
  .box-card{
    text-align: left;
  }
  .el-dialog__header{
    display: none;
  }
  .text {
    font-size: 14px;
  }
  .item {
    margin-bottom: 18px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
}
</style>
